import React, {ChangeEvent, FormEvent} from "react"
import {Redsyskonfig} from "../../models/Redsyskonfig"
import {ValidierungsAnfrageStatus} from "../../models/ValidierungsAnfrageResponse"
import {Benutzerangaben} from "./Benutzerangaben"
import {RedaktionssystemAuswahl} from "./RedaktionssystemAuswahl"

const httpProtocolEnabled = window.env.HTTP_PROTOCOL_ENABLED === "true"

interface EingabeProps {
  isCancelDisabled: boolean
  isValidateDisabled: boolean
  redsyskonfig: Redsyskonfig
  status: ValidierungsAnfrageStatus
  ladeRedaktionssystem: (ladeCode: string) => void
  setIsCancelDisabled: (cancelDisabled: boolean) => void
  stoppeValidierung: () => void
  validierungsAnfrage: (pfad: string, username: string, password: string, isAuthDisabled: boolean) => void
  setIsValidateDisabled: (validateDisabled: boolean) => void
}

//Komponente zur Eingabe der Validierungsparameter
export const Eingabe: React.FC<EingabeProps> = ({
  isCancelDisabled,
  isValidateDisabled,
  status,
  ladeRedaktionssystem,
  redsyskonfig,
  setIsCancelDisabled,
  setIsValidateDisabled,
  stoppeValidierung,
  validierungsAnfrage
}) => {
  const [isAuthDisabled, setIsAuthDisabled] = React.useState<boolean>(true)
  const [isValidUrl, setIsValidUrl] = React.useState<boolean>(false)
  const [password, setPassword] = React.useState<string>("")
  const [pfad, setPfad] = React.useState<string>("")
  const [username, setUsername] = React.useState<string>("")

  const changePfad = (event: ChangeEvent<HTMLInputElement>) => {
    setPfad(event.target.value)
  }

  React.useLayoutEffect(() => {
    // Verzeichnisse sind nicht erlaubt
    if (pfad.trimEnd().endsWith("/")) {
      setIsValidUrl(false)
      return
    }

    try {
      const newUrl = new URL(pfad)
      setIsValidUrl(newUrl.protocol === "https:" || (httpProtocolEnabled && newUrl.protocol === "http:"))
    } catch (err) {
      setIsValidUrl(false)
    }
  }, [pfad])

  React.useEffect(() => {
    const urlFromParam = new URLSearchParams(window.location.search).get("url")
    if (urlFromParam) {
      setPfad(window.atob(urlFromParam))
    }
  }, [])

  React.useEffect(() => {
    const validating =
      status === "WAITING" ||
      status === "READING_INDEX" ||
      status === "VALIDATING" ||
      status === "CONNECTING_TO_BACKEND" ||
      status === "TRYING_TO_RECONNECT"

    if (validating) {
      return
    }

    const validOhneAuth = isValidUrl && isAuthDisabled
    const validMitAuth = isValidUrl && !isAuthDisabled && username !== "" && password !== ""

    if (validOhneAuth || validMitAuth) {
      setIsValidateDisabled(false)
    } else {
      setIsValidateDisabled(true)
    }
  }, [isAuthDisabled, isValidUrl, password, username, setIsValidateDisabled, status])

  //Bei einem Submit werden Pfad und credentials ans Backend uebergeben
  const submitEvent = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    if (pfad) {
      validierungsAnfrage(pfad, username, password, isAuthDisabled)
      setIsCancelDisabled(false)
      setIsValidateDisabled(true)
    }
  }

  return (
    <div>
      <form onSubmit={submitEvent}>
        <div className="url-wrapper">
          <div className="header-url">Geben Sie die URL zu einer Indexdatei an.</div>
          <div className="rectangle-url-eingabe">
            <div className="rectangle-url-inner">
              {/*Eingabe fuer den Pfad zur Indexdatei*/}
              <div>
                <input
                  className="url-eingabe"
                  placeholder={
                    httpProtocolEnabled
                      ? "Unterstützte Protokolle sind http und https"
                      : "Es wird nur https unterstützt"
                  }
                  value={pfad}
                  onChange={changePfad}
                />
              </div>
              {!isValidUrl && Boolean(pfad.length) && (
                <div className="error-text">
                  <span>Die eingegebene Url ist ungültig</span>
                </div>
              )}
            </div>
          </div>
        </div>
        <Benutzerangaben
          isAuthDisabled={isAuthDisabled}
          setUsername={setUsername}
          setPassword={setPassword}
          setIsAuthDisabled={setIsAuthDisabled}
        />
        {/*Komponente zur Auswahl des Redaktionssystems*/}
        <RedaktionssystemAuswahl ladeRedaktionssystem={ladeRedaktionssystem} redsyskonfig={redsyskonfig} />
        <div className="setclear" />

        <div className="submit-wrapper">
          <input
            className="cancelbutton"
            type="button"
            value="Validierung abbrechen"
            onClick={stoppeValidierung}
            disabled={isCancelDisabled}
          />
          <input className="startbutton" type="submit" value="Validieren" disabled={isValidateDisabled} />
        </div>
      </form>
    </div>
  )
}
